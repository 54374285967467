import React, { useState } from "react"
import "../styles/index.css"
import { navigate, useStaticQuery, graphql, Link } from "gatsby"

import Img from "gatsby-image"

import DefaultLayout from "../layout/defaultLayout"
import HorizontalLine from "../components/HorizontalLine"

const IndexFooter = () => {
    return (
        <div className="padding-global">
            <div className=" container-xxlarge vertical-border_light">
                <div className="index-footer flex-container flex-center">
                    <div className="main-container flex-container flex-center index-footer-subcontainer">
                        <h4
                            className="subtitle"
                            style={{
                                color: "white",
                                textAlign: "start",
                                fontWeight: 400,
                            }}
                        >
                            Ready to try
                            <br />
                            Paratus?
                        </h4>
                        <Link to="/demo">
                            <button
                                className="default-btn btn-secondary"
                                style={{ padding: "1.25rem 1.25rem" }}
                            >
                                Request a Free Demo
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

const IndexPage = () => {
    // Create gatsby image format for title images/logos
    const data = useStaticQuery(graphql`
        query {
            laptopAndPhone: file(relativePath: { eq: "laptop-and-phone.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1200, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            dod: file(relativePath: { eq: "dod.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            responders: file(relativePath: { eq: "responders.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            autoSynth: file(relativePath: { eq: "auto-synth.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            schedule: file(relativePath: { eq: "schedule.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            text: file(relativePath: { eq: "text.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            predictive: file(relativePath: { eq: "predictive.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            calendar_icon: file(relativePath: { eq: "calendar_icon.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            chart_icon: file(relativePath: { eq: "chart_icon.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            contact_icon: file(relativePath: { eq: "contact_icon.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            logo_943: file(relativePath: { eq: "943rd.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            logo_307: file(relativePath: { eq: "307th.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            logo_916: file(relativePath: { eq: "916th.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            logo_924: file(relativePath: { eq: "924th.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    const [demoEmail, setDemoEmail] = useState("")

    const handleSubmitDemo = (e) => {
        e.preventDefault()

        navigate(`/demo?email=${demoEmail}`)
    }

    return (
        <DefaultLayout title="Home">
            <div className="padding-global">
                <div className=" container-xxlarge vertical-border_light">
                    <div className="flex-container flex-center full-basis">
                        <div
                            className="main-container-top flex-center "
                            style={{
                                justifyContent: "space-between",
                                width: "100%",
                                backgroundColor: "black",
                            }}
                        >
                            <div className="column-container half-width-always with-margin">
                                <h1
                                    className="title"
                                    style={{ color: "var(--light-gray)" }}
                                >
                                    Redefining readiness
                                    <br />
                                    management
                                </h1>

                                <p
                                    className="mobile-only description tagline-2"
                                    style={{ color: "var(--light-gray)" }}
                                >
                                    Paratus eliminates the administrative burden
                                    of managing personnel readiness so users can
                                    focus on mission-critical work
                                </p>
                                <p
                                    className="desktop-only description tagline-2"
                                    style={{ color: "var(--medium-gray)" }}
                                >
                                    Paratus eliminates the administrative burden
                                    of managing personnel readiness so users can
                                    focus on mission-critical work
                                </p>
                                <div
                                    className="flex-container  full-width"
                                    style={{
                                        marginTop: "1rem",
                                        marginBottom: "1rem",
                                    }}
                                >
                                    <Link to="/demo">
                                        <button className="default-btn">
                                            TRY PARATUS
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div
                                className="column-container"
                                style={{ marginRight: "2rem" }}
                            >
                                <Img
                                    fluid={
                                        data.laptopAndPhone.childImageSharp
                                            .fluid
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <HorizontalLine />
            <div className="padding-global">
                <div className=" container-xxlarge vertical-border_light">
                    <div className="flex-container flex-center full-basis">
                        <div
                            style={{
                                display: "block",
                                marginBottom: "6rem",
                                marginTop: "3rem",
                            }}
                            className="flex-container flex-center main-container full-basis"
                        >
                            <div
                                className="title centered"
                                style={{
                                    marginBottom: "1rem",
                                }}
                            >
                                Manage the full training lifecycle
                            </div>
                            <div className="description centered">
                                Automated workflows lead to better readiness
                                outcomes
                            </div>
                        </div>

                        <div
                            className="flex-container flex-center main-container full-basis"
                            style={{
                                gap: "1rem",
                                marginBottom: "4rem",
                                justifyContent: "space-evenly",
                            }}
                        >
                            <div>
                                <div className="flex-container">
                                    <Img
                                        className="small-icon"
                                        fluid={
                                            data.calendar_icon.childImageSharp
                                                .fluid
                                        }
                                    />
                                    <div className="description">Prepare</div>
                                </div>
                                <div
                                    className="small-description"
                                    // style={{ width: "70%" }}
                                >
                                    Reduce time spent manipulating data to
                                    <br />
                                    create and schedule training plans
                                </div>
                            </div>

                            <div className="vertical-line-for-row" />

                            <div>
                                <div className="flex-container">
                                    <Img
                                        className="small-icon"
                                        fluid={
                                            data.contact_icon.childImageSharp
                                                .fluid
                                        }
                                    />
                                    <div className="description">Execute</div>
                                </div>

                                <div className="small-description">
                                    Communicate at scale and track task
                                    <br />
                                    completion in real-time
                                </div>
                            </div>
                            <div className="vertical-line-for-row" />
                            <div>
                                <div className="flex-container">
                                    <Img
                                        className="small-icon"
                                        fluid={
                                            data.chart_icon.childImageSharp
                                                .fluid
                                        }
                                    />
                                    <div className="description">Analyze</div>
                                </div>
                                <div className="small-description">
                                    Receive reporting and greater visibility
                                    <br />
                                    into organizational progress
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <HorizontalLine />
            <div className="padding-global">
                <div className=" container-xxlarge vertical-border_light">
                    <div className="flex-container flex-center full-basis">
                        <div id="product">
                            <div
                                className="title centered"
                                style={{
                                    marginBottom: "1rem",
                                    marginTop: "1rem",
                                    flexBasis: "60%",
                                }}
                            >
                                Equip users with modern tools to optimize time
                                and resources{" "}
                            </div>
                            <div className="flex-container flex-center">
                                <div className="card-content-row limit-width">
                                    <div className="card-content-item flex-container flex-center order-2">
                                        <Img
                                            fluid={
                                                data.autoSynth.childImageSharp
                                                    .fluid
                                            }
                                            style={{ width: "90%" }}
                                        />
                                    </div>
                                    <div
                                        className="card-content-item-small order-1"
                                        style={{ textAlign: "left" }}
                                    >
                                        <h3
                                            className="subtitle align-by-screen-size"
                                            style={{
                                                color: "black",
                                                margin: 0,
                                            }}
                                        >
                                            Automated Data Ingestion
                                        </h3>
                                        <p className="small-description align-by-screen-size">
                                            Paratus’s proprietary algorithms
                                            dynamically parse unique data sheets
                                            and organize the information into
                                            digestible profiles.
                                            <br />
                                            <br />
                                            Simply upload your readiness data,
                                            and Paratus will automatically
                                            populate dashboards that display
                                            personalized profiles within a few
                                            minutes.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="flex-container flex-center">
                                <div className="card-content-row limit-width">
                                    <div className="card-content-item flex-container flex-center order-2">
                                        <Img
                                            fluid={
                                                data.schedule.childImageSharp
                                                    .fluid
                                            }
                                            style={{ width: "90%" }}
                                        />
                                    </div>
                                    <div
                                        className="card-content-item-small order-1"
                                        style={{ textAlign: "left" }}
                                    >
                                        <h3
                                            className="subtitle align-by-screen-size"
                                            style={{
                                                color: "black",
                                                margin: 0,
                                            }}
                                        >
                                            Intelligent Scheduling
                                        </h3>
                                        <p className="small-description align-by-screen-size">
                                            Automatic conflict detection and
                                            task prioritization ensure that your
                                            organization is focusing on the most
                                            pertinent tasks to maximize
                                            readiness.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="flex-container flex-center">
                                <div className="card-content-row limit-width">
                                    <div className="card-content-item flex-container flex-center order-2">
                                        <Img
                                            fluid={
                                                data.text.childImageSharp.fluid
                                            }
                                            style={{ width: "90%" }}
                                        />
                                    </div>
                                    <div
                                        className="card-content-item-small order-1"
                                        style={{ textAlign: "left" }}
                                    >
                                        <h3
                                            className="subtitle align-by-screen-size"
                                            style={{
                                                color: "black",
                                                margin: 0,
                                            }}
                                        >
                                            Live Transparency
                                        </h3>
                                        <p className="small-description align-by-screen-size">
                                            Personalized training orders are
                                            sent to trainees via SMS, and once
                                            trainees respond with confirmation
                                            of task completion, leaders receive
                                            live summary statistics of their
                                            unit’s readiness.
                                            <br />
                                            <br />
                                            Maximize transparency and
                                            communication by sending messages
                                            across entire organizations and
                                            easily analyzing responses.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="flex-container flex-center">
                                <div className="card-content-row limit-width">
                                    <div className="card-content-item flex-container flex-center order-2">
                                        <Img
                                            fluid={
                                                data.predictive.childImageSharp
                                                    .fluid
                                            }
                                            style={{ width: "90%" }}
                                        />
                                    </div>
                                    <div
                                        className="card-content-item-small order-1"
                                        style={{ textAlign: "left" }}
                                    >
                                        <h3
                                            className="subtitle align-by-screen-size"
                                            style={{
                                                color: "black",
                                                margin: 0,
                                            }}
                                        >
                                            Predictive Readiness Analytics
                                        </h3>
                                        <p className="small-description align-by-screen-size">
                                            Paratus gives leadership access to
                                            granular and holistic insights
                                            through custom data visualizations,
                                            allowing for instant understanding
                                            of organizational readiness.
                                            <br />
                                            <br />
                                            Historical training data informs
                                            models that predict future readiness
                                            levels to drive proactive,
                                            data-driven management decisions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <HorizontalLine />
            <div className="padding-global">
                <div
                    id="customer"
                    className=" container-xxlarge vertical-border_light"
                >
                    <div className="flex-container flex-center full-basis">
                        <div className="flex-container flex-center">
                            <div
                                className="title centered"
                                style={{
                                    marginBottom: "6rem",

                                    marginTop: "3rem",
                                    flexBasis: "70%",
                                }}
                            >
                                Paratus is the trusted solution for
                                <br />
                                many mission-critical organizations
                            </div>
                        </div>

                        <div
                            className="flex-container flex-center main-container full-basis"
                            style={{
                                gap: "1rem",
                                justifyContent: "space-evenly",
                            }}
                        >
                            <Img
                                className="client-logos"
                                fluid={data.logo_943.childImageSharp.fluid}
                            />
                            <Img
                                className="client-logos"
                                fluid={data.logo_307.childImageSharp.fluid}
                            />
                            <Img
                                className="client-logos"
                                fluid={data.logo_916.childImageSharp.fluid}
                            />
                            <Img
                                className="client-logos"
                                fluid={data.logo_924.childImageSharp.fluid}
                            />
                        </div>
                        <div
                            style={{
                                marginBottom: "3rem",
                            }}
                            className="flex-container flex-center full-basis"
                        >
                            <div className="card quote-card">
                                <em>
                                    “It’s incredible how fast it is. In three
                                    minutes, I know what needs to get done and
                                    in a single press of the button later, every
                                    <br />
                                    member of my team has their training
                                    orders.”
                                </em>
                                <br />
                                <br />
                                <b>
                                    Section Chief - 916th AMXS, Seymour Johnson
                                    Air Force Base
                                </b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <HorizontalLine />

            {/*<div*/}
            {/*    id="use-cases"*/}
            {/*    className="main-container"*/}
            {/*    style={{ paddingBottom: "3rem" }}*/}
            {/*>*/}
            {/*    <div className="full-width">*/}
            {/*        <h1 className="title centered">*/}
            {/*            Backed by Department of Defense*/}
            {/*            <br />*/}
            {/*            Funded Research*/}
            {/*        </h1>*/}
            {/*    </div>*/}
            {/*    <div className="full-width percent-container">*/}
            {/*        <div className="percent-subcontainer-left">*/}
            {/*            <div className="percent-body">*/}
            {/*                <div>*/}
            {/*                    <h5 className="percent-number">*/}
            {/*                        60%*/}
            {/*                    </h5>*/}
            {/*                    <p className="percent-text">*/}
            {/*                        Elimination of*/}
            {/*                        <br />*/}
            {/*                        wasted time*/}
            {/*                    </p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="percent-subcontainer-right">*/}
            {/*            <div className="percent-body">*/}
            {/*                <div>*/}
            {/*                    <h5 className="percent-number">*/}
            {/*                        40%*/}
            {/*                    </h5>*/}
            {/*                    <p className="percent-text">*/}
            {/*                        Increase in overall*/}
            {/*                        <br />*/}
            {/*                        satisfaction*/}
            {/*                    </p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="percent-subcontainer-left">*/}
            {/*            <div className="percent-body">*/}
            {/*                <div>*/}
            {/*                    <h5 className="percent-number">*/}
            {/*                        80%*/}
            {/*                    </h5>*/}
            {/*                    <p className="percent-text">*/}
            {/*                        Decrease in training*/}
            {/*                        <br />*/}
            {/*                        preparation time*/}
            {/*                    </p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="percent-subcontainer-right">*/}
            {/*            <div className="percent-body">*/}
            {/*                <div>*/}
            {/*                    <h5 className="percent-number">*/}
            {/*                        10%*/}
            {/*                    </h5>*/}
            {/*                    <p className="percent-text">*/}
            {/*                        Increase in overall*/}
            {/*                        <br />*/}
            {/*                        task completion*/}
            {/*                    </p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<HorizontalLine />*/}

            {/*<div*/}
            {/*    style={{*/}
            {/*        marginBottom: "4rem",*/}
            {/*    }}*/}
            {/*    id="outcomes"*/}
            {/*>*/}
            {/*    <h3 className="title centered full-basis">*/}
            {/*        Our Outcomes*/}
            {/*    </h3>*/}
            {/*    <div className="flex-container flex center">*/}
            {/*        <div className="outcome-card-container outcome-card-container-left">*/}
            {/*            <div className="outcome-card card">*/}
            {/*                Meaningful time and*/}
            {/*                <br />*/}
            {/*                resource savings*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="outcome-card-container outcome-card-container-right">*/}
            {/*            <div className="outcome-card card">*/}
            {/*                Elimination of tedious, manual work*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="outcome-card-container outcome-card-container-left">*/}
            {/*            <div className="outcome-card card">*/}
            {/*                Better accountability and*/}
            {/*                <br />*/}
            {/*                communication across teams*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="outcome-card-container outcome-card-container-right">*/}
            {/*            <div className="outcome-card card">*/}
            {/*                Scalability for any organization*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<HorizontalLine />*/}

            <IndexFooter />

            <HorizontalLine />
        </DefaultLayout>
    )
}

export default IndexPage
